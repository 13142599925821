@font-face {
   font-family: 'Proxima Nova Regular';
   src: url('./fonts/proximanova-regular.ttf');
}

@font-face {
   font-family: 'Proxima Nova Bold';
   src: url('./fonts/proximanova-bold.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Proxima Nova Regular';
}
